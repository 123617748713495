import hostMap from '../hostMap';
// 配置子应用路由及端口
export const routeObj = {
  organization: {
    url: hostMap('//localhost:7301/'),
    navs: [
      {
        url: '/department',
        name: '组织架构',
        img: 'department',
      },
      // {
      //   url: '/member',
      //   name: '成员管理',
      //   img: 'member',
      // },
      {
        url: '/job',
        name: '职务',
        img: 'job',
      },
      {
        url: '/operateRecord',
        name: '操作记录',
        img: 'record',
      },
    ],
  },
  accountWorkspace: {
    url: hostMap('//localhost:7302/'),
    navs: [
      {
        url: '/accountManage',
        name: '帐号列表',
        img: 'account',
      },
      {
        url: '/studentWhitelist',
        name: '学生白名单',
        img: 'student-white',
      },
      {
        url: '/operateRecord',
        name: '操作记录',
        img: 'record',
      },
    ],
  },
  accountCustomer: {
    url: hostMap('//localhost:7303/'),
    navs: [
      {
        url: '/clientManage',
        name: '客户',
        img: 'connection',
      },
      {
        url: '/setClientManage',
        name: '客户经理',
        img: 'manager',
      },
      {
        url: '/operateRecord',
        name: '操作记录',
        img: 'record',
      },
    ],
  },
  accountPersonal: {
    url: hostMap('//localhost:7304/'),
    navs: [
      {
        url: '/accountManage',
        name: '个人帐号',
        img: 'account',
      },
      {
        url: '/operateRecord',
        name: '操作记录',
        img: 'record',
      },
    ],
  },
  permission: {
    url: hostMap('//localhost:7305/'),
    navs: [
      {
        url: '/memberManage',
        name: '成员列表',
        img: 'memberlist',
      },
      {
        url: '/powerManage',
        name: '权限组',
        img: 'power',
      },
      {
        url: '/operateRecord',
        name: '操作记录',
        img: 'record',
      },
    ],
  },
  appCentre: {
    url: hostMap('//localhost:7306/'),
    navs: [
      // {
      //   url: '/boutiqueApplication',
      //   name: '精选',
      //   img: 'boutique',
      // },
      {
        url: '/classification',
        name: '应用中心',
        img: 'classification',
      },
    ],
  },
  appManage: {
    url: hostMap('//localhost:7307/'),
    navs: [
      {
        url: '/applicationList',
        name: '全部应用',
        img: 'applymanage',
      },
      // {
      //   url: '/recommendedSetting',
      //   name: '推荐设置',
      //   img: 'recommend',
      // },
      {
        url: '/operateRecord',
        name: '操作记录',
        img: 'record',
      },
    ],
  },
  sourceLibrary: {
    url: hostMap('//localhost:7308/'),
    navs: [
      {
        url: '/resourceList',
        name: '平台库',
        img: 'resource',
      },
    ],
  },
  groupsSpace: {
    url: hostMap('//localhost:7309/'),
    navs: [
      {
        url: '/dynamic',
        name: '动态',
        img: 'dynamic',
      },
      {
        url: '/groupsManage',
        name: '管理',
        img: 'manage',
      },
      {
        url: '/audit',
        name: '审核',
        img: 'audit',
      },
    ],
  },
  baseDataManage: {
    url: hostMap('//localhost:7310/'),
    navs: [
      // {
      //   url: "/publicManage",
      //   name: "公告管理",
      //   img: "public"
      // },
      {
        url: '/swiper',
        name: '轮播图',
        img: 'swiper',
      },
      {
        url: '/showPlace',
        name: '展示位置',
        img: 'showPlace',
      },
      {
        url: '/operateRecord',
        name: '操作记录',
        img: 'record',
      },
    ],
  },
  sourceManage: {
    url: hostMap('//localhost:7311/'),
    navs: [
      {
        url: '/teachingCatalogueManage',
        name: '教材和目录',
        img: 'teaching',
      },
      {
        url: '/knowledgePointManage',
        name: '知识点',
        img: 'knowledge',
      },
      {
        url: '/courseBehave',
        name: '学科素养',
        img: 'course',
      },
      {
        url: '/perceiveTarget',
        name: '认知目标',
        img: 'perceive',
      },
      {
        url: '/fosterQuality',
        name: '培养品质',
        img: 'foster',
      },
      {
        url: '/commonResourceManage',
        name: '公共资源',
        img: 'common',
      },
      {
        url: '/reviewStaging',
        name: '审核工作台',
        img: 'reviewStaging',
      },
      {
        url: '/reviewProcessManage',
        name: '审核流程管理',
        img: 'reviewProcessManage',
      },
    ],
  },
  groupsSpaceConfig: {
    url: hostMap('//localhost:7312/'),
    navs: [
      {
        url: '/groupManage',
        name: '群组动态管理',
        img: 'group',
      },
    ],
  },
  initializeConfig: {
    url: hostMap('//localhost:7313/'),
    navs: [
      {
        url: '/applicationManage',
        name: '应用管理',
        img: 'applymanage',
      },
      {
        url: '/routeManage',
        name: '动态路由',
        img: 'applymanage',
      },
      {
        url: '/menuManage',
        name: '菜单管理',
        img: 'menu',
      },
    ],
  },
  prepareLesson: {
    url: hostMap('//localhost:7314/'),
    navs: [
      {
        url: '/my',
        name: '我的',
        img: 'mySource',
      },
      // {
      //   url: '/collect',
      //   name: '收藏',
      //   img: 'collect',
      // },
      // {
      //   url: "/share",
      //   name: "分享",
      //   img: "share"
      // }
    ],
  },
  myGoods: {
    url: hostMap('//localhost:7315/'),
    navs: [
      {
        url: '/details',
        name: '我的会员',
        img: 'myGoods',
      },
    ],
  },
  vipStrategy: {
    url: hostMap('//localhost:7316/'),
    navs: [
      {
        url: '/list',
        name: '会员策略',
        img: 'vipStrategy',
      },
      {
        url: '/record',
        name: '操作记录',
        img: 'record',
      },
    ],
  },
  ordersManager: {
    url: hostMap('//localhost:7317/'),
    navs: [
      {
        url: '/details',
        name: '订单详情',
        img: 'ordersManager',
      },
    ],
  },
  editHomeweb: {
    url: hostMap('//localhost:7318/'),
    navs: [
      {
        url: '/columnContent',
        name: '栏目内容',
        img: 'homePageContainer',
      },
      {
        url: '/swiper',
        name: '轮播图',
        img: 'swiper',
      },
      {
        url: '/pageConfigure',
        name: '界面配置',
        img: 'homePageConfigure',
      },
      {
        url: '/resource',
        name: '资源',
        img: 'homePageResource',
      },
      {
        url: '/member',
        name: '会员',
        img: 'homePageMember',
      },
      {
        url: '/operateRecord',
        name: '操作记录',
        img: 'record',
      },
    ],
  },
  classPhoto: {
    url: hostMap('//localhost:7319/'),
    navs: [
      {
        url: '/personalPhotoes',
        name: '个人相册',
        img: 'ordersManager',
      },
      {
        url: '/classPhotoes',
        name: '班级相册',
        img: 'classPhotoes',
      },
    ],
  },
  weather: {
    url: hostMap('//localhost:7320/'),
    navs: [
      {
        url: '/info',
        name: '天气',
        img: 'weather',
      },
    ],
  },
  ksResource: {
    url: hostMap('//localhost:7321/'),
    navs: [
      {
        url: '/documentary',
        name: '纪录片',
        img: 'documentary',
      },
      {
        url: '/microVideo',
        name: '微课视频',
        img: 'microVideo',
      },
    ],
  },
  externalChannel: {
    url: hostMap('//localhost:7322/'),
    navs: [
      {
        url: '/externalChannel',
        name: '外部资源导航',
        img: 'externalChannel',
      },
    ],
  },
  manageChannel: {
    url: hostMap('//localhost:7323/'),
    navs: [
      {
        url: '/manageChannel',
        name: '外部资源导航管理',
        img: 'manageChannel',
      },
    ],
  },
  manageTopic: {
    url: hostMap('//localhost:7324/'),
    navs: [
      {
        url: '/manageTopic',
        name: '专栏管理',
        img: 'manageTopic',
      },
    ],
  },
  specialTopic: {
    url: hostMap('//localhost:7325/'),
    navs: [
      {
        url: '/specialTopic',
        name: '专栏',
        img: 'specialTopic',
      },
    ],
  },
  manageAddress: {
    url: hostMap('//localhost:7326/'),
    navs: [
      {
        url: '/manageAddress',
        name: '机构地址管理',
        img: 'manageAddress',
      },
    ],
  },
  chatgpt: {
    url: hostMap('//localhost:7327/'),
    navs: [
      {
        url: '/chat',
        name: '智能助手',
        img: 'chatgpt',
      },
    ],
  },
  manageActivity: {
    url: hostMap('//localhost:7328/'),
    navs: [
      {
        url: '/manageActivity',
        name: '活动组管理',
        img: 'manageActivity',
      },
      {
        url: '/manageAuth',
        name: '权限管理',
        img: 'manageAuth',
      },
      {
        url: '/activityList',
        name: '活动列表',
        img: 'activityList',
      },
      {
        url: '/manageTheme',
        name: '主题管理',
        img: 'manageTheme',
      },
      {
        url: '/operateRecord',
        name: '操作记录',
        img: 'record',
      },
    ],
  },
  classSetting: {
    url: hostMap('//localhost:7329/'),
    navs: [
      {
        url: '/groupManage',
        name: '分组管理',
        img: 'groupManage',
      },
      {
        url: '/memberManage',
        name: '人员管理',
        img: 'memberManage',
      },
      {
        url: '/teamMember',
        name: '团队成员分配',
        img: 'team-member',
      },
      {
        url: '/classAssignment',
        name: '班级职务分配',
        img: 'class-job',
      },
    ],
  },
  classInteraction: {
    url: hostMap('//localhost:7330/'),
    navs: [
      {
        url: '/deviceManage',
        name: '设备管理',
        img: 'deviceManage',
      },
      {
        url: '/interactData',
        name: '互动数据',
        img: 'interactData',
      },
      {
        url: '/evaluationManage',
        name: '评价管理',
        img: 'evaluationManage',
      },
      {
        url: '/evaluationData',
        name: '评价数据',
        img: 'evaluationData',
      },
      {
        url: '/lessonResourceAudit',
        name: '推送管理',
        img: 'lessonResourceAudit',
      },
      {
        url: '/versionUpdateManage',
        name: '版本更新管理',
        img: 'versionUpdateManage',
      },
    ],
  },
  classReports: {
    url: hostMap('//localhost:7331/'),
    navs: [
      {
        url: '/report',
        name: '课堂报告',
        img: 'report',
      },
      {
        url: '/evaluate',
        name: '课堂评价',
        img: 'evaluate',
      },
      {
        url: '/classInteractData',
        name: '互动数据',
        img: 'interactData',
      },
    ],
  },
  activity: {
    url: hostMap('//localhost:7332/'),
    navs: [
      {
        url: '/activityMy',
        name: '我的活动',
        img: 'activityMy',
      },
      {
        url: '/activitySquare',
        name: '活动广场',
        img: 'activitySquare',
      },
      {
        url: '/activityAdd',
        name: '创建活动',
        img: 'activityAdd',
      },
      {
        url: '/activityManage',
        name: '活动管理',
        img: 'activityManage',
      },
    ],
  },
  learnCentre: {
    url: hostMap('//localhost:7333/'),
    navs: [
      {
        url: '/mine',
        name: '自主学习',
        img: 'mySource',
      },
      {
        url: '/collect',
        name: '收藏',
        img: 'collect',
      },
    ],
  },
  expertLibrary: {
    url: hostMap('//localhost:7334/'),
    navs: [
      {
        url: '/expertLibrary',
        name: '专家库',
        img: 'expertLibrary',
      },
    ],
  },
  evaluate: {
    url: hostMap('//localhost:7335/'),
    navs: [
      {
        url: '/evaluatable',
        name: '评价',
        img: 'eva-evaluate',
      },
      {
        url: '/viewable',
        name: '查看',
        img: 'eva-view',
      },
      {
        url: '/manageable',
        name: '管理',
        img: 'eva-manage',
      },
    ],
  },
  learningTools: {
    url: hostMap('//localhost:7336/'),
    navs: [
      {
        url: '/learningTools',
        name: '思维导图',
        img: 'mind',
      },
      {
        url: '/drawingBoard',
        name: '几何画板',
        img: 'drawing',
      },
      {
        url: '/formulaEdit',
        name: '公式编辑',
        img: 'editor',
      },
      {
        url: "/periodicTables",
        name: "元素周期表",
        img: "chemical"
      },
      {
        url: "/tdtMap",
        name: "地形图",
        img: "map"
      },
      {
        url: "/simulationExperiment",
        name: "仿真实验",
        img: "experiment"
      },
    ]
  },
  certificateEditor: {
    url: hostMap('//localhost:7337/'),
    navs: [
      {
        url: '/certificateEditor',
        name: '未发放',
        img: 'noCertificate',
      },
      {
        url: '/hasCertificate',
        name: '已发放',
        img: 'hasCertificate',
      },
    ],
  },
  classManage: {
    url: hostMap('//localhost:7338/'),
    navs: [
      {
        url: '/jobManage',
        name: '班级职务管理',
        img: 'job-manage',
      },
      {
        url: '/teamManage',
        name: '班级团队管理',
        img: 'team-manage',
      },
    ],
  },
  certificateManage: {
    url: hostMap('//localhost:7339/'),
    navs: [
      {
        url: '/certificateManage',
        name: '证书管理',
        img: 'certificateManage',
      },
    ],
  },
  evaluateSystemQuota: {
    url: hostMap('//localhost:7340/'),
    navs: [
      {
        url: '/systemQuota',
        name: '系统指标',
        img: 'systemQuota',
      },
    ],
  },
  evaluateAgencyManage: {
    url: hostMap('//localhost:7341/'),
    navs: [
      {
        url: '/agencyQuota',
        name: '机构指标',
        img: 'agencyQuota',
      },
      {
        url: '/evaluateConfig',
        name: '评价配置',
        img: 'evaluateConfig',
      },
    ],
  },
  inspirationCentre: {
    url: hostMap('//localhost:7342/'),
    navs: [
      {
        url: '/inspirationCentre',
        name: '主页',
        img: 'mainPage',
      },
      {
        url: '/creation',
        name: '创作',
        img: 'createPage',
      },
      {
        url: '/collect',
        name: '收藏',
        img: 'collect',
      },
      {
        url: '/repository',
        name: '入库',
        img: 'getIn',
      },
      {
        url: '/shares',
        name: '分享',
        img: 'shares',
      },
      {
        url: '/recycle',
        name: '回收站',
        img: 'reget',
      },
    ],
  },
  classStuReports: {
    url: hostMap('//localhost:7343/'),
    navs: [
      {
        url: '/report',
        name: '课堂报告',
        img: 'mainPage',
      },
    ],
  },
  comprehensiveEvaluate: {
    url: hostMap('//localhost:7344/'),
    navs: [
      {
        url: '/overview',
        name: '总览',
        img: 'comprehensive-overview',
      },
      {
        url: '/standard',
        name: '达标验收',
        img: 'comprehensive-standard',
      },
      {
        url: '/report',
        name: '验收报告',
        img: 'comprehensive-report',
      },
      {
        url: '/promote',
        name: '晋级',
        img: 'comprehensive-promote',
      },
      {
        url: '/selection',
        name: '评选',
        img: 'comprehensive-selection',
      },
      {
        url: '/setting',
        name: '设置',
        img: 'comprehensive-setting',
      },
    ],
  },
  orgSourceManage: {
    url: hostMap('//localhost:7345/'),
    navs: [
      {
        url: '/resourceList',
        name: '资源列表',
        img: 'teaching',
      },
      {
        url: '/orgReviewStaging',
        name: '审核工作台',
        img: 'knowledge',
      },
      {
        url: '/orgReviewProcessManage',
        name: '流程管理',
        img: 'course',
      },
      {
        url: '/orgTeachingCatalogueManage',
        name: '教材目录',
        img: 'perceive',
      },
      {
        url: '/orgKnowledgePointManage',
        name: '知识点',
        img: 'foster',
      },
      {
        url: '/orgCourseBehave',
        name: '学科素养',
        img: 'common',
      },
      {
        url: '/orgPerceiveTarget',
        name: '认知目标',
        img: 'reviewStaging',
      },
      {
        url: '/orgFosterQuality',
        name: '培养品质',
        img: 'reviewProcessManage',
      },
    ],
  },
  parentsManage: {
    url: hostMap('//localhost:7346/'),
    navs: [
      {
        url: '/manage',
        name: '信息管理',
        img: 'parents-manage-manage',
      },
      {
        url: '/records',
        name: '操作记录',
        img: 'parents-manage-record',
      },
    ],
  },
  chatManage: {
    url: hostMap('//localhost:7347/'),
    navs: [
      {
        url: '/function',
        name: '功能管理',
        img: 'chat-manage-function',
      },
      {
        url: '/customer',
        name: '客服管理',
        img: 'chat-manage-customer',
      },
      {
        url: "/statistics",
        name: "数据统计",
        img: "chat-manage-statistics"
      }
    ],
  },
  educationalInfo: {
    url: hostMap('//localhost:7348/'),
    navs: [
      {
        url: '/termManagement',
        name: '学期信息',
        img: 'nameManagement',
      },
      {
        url: "/nameManagement",
        name: "课程名称",
        img: "termManagement"
      },
      {
        url: "/teachingManagement",
        name: "班级任教信息",
        img: "teachingManagement"
      },
      {
        url: "/classInformation",
        name: "班级开课信息",
        img: "classInformation"
      },
    ],
  },
  chatQa: {
    url: hostMap('//localhost:7349/'),
    navs: [
      {
        url: '/sessions',
        name: '聊天',
        img: 'chat-qa-sessions',
      },
      {
        url: '/contacts',
        name: '通讯录',
        img: 'chat-qa-contacts',
      },
      {
        url: '/mass',
        name: '群发消息',
        img: 'chat-qa-mass',
      },
      {
        url: '/label',
        name: '标签管理',
        img: 'chat-qa-label',
      },
    ],
  },
  todoList: {
    url: hostMap('//localhost:7350/'),
    navs: [
      {
        url: '/todo',
        name: '主页',
        img: 'todo-list',
      }
    ],
  },
  boxiaoCenter: {
    url: 'edudcs.zhixuehuijiao.cn/fe-pc/b/fe_system_personal',
    navs: [
      {
        url: "/",
        name: "个人中心",
        img: "comprehensive-overview"
      }
    ]
  },
  boxiaoTranscript: {
    url: 'edudcs.zhixuehuijiao.cn/fe-pc/b/fe_k12_exam',
    navs: [
      {
        url: "/",
        name: "成绩",
        img: "comprehensive-overview"
      }
    ]
  },
  boxiaoWeekSetting: {
    url: 'edudcs.zhixuehuijiao.cn/fe-pc/b/fe_calendar',
    navs: [
      {
        url: "/weekSetting",
        name: "校历",
        img: "comprehensive-overview"
      }, {
        url: "/weekTask",
        name: "周工作安排",
        img: "comprehensive-overview"
      }
    ]
  },
  boxiaoAchievementManage: {
    url: 'edudcs.huixuecloud.cn/fe-pc/b/fe_k12_exam',
    navs: [
      {
        url: "/examProject",
        name: "成绩项目",
        img: "comprehensive-overview"
      }, {
        url: "/examSearch",
        name: "成绩查询",
        img: "comprehensive-overview"
      }, {
        url: "/examReport",
        name: "成绩报告",
        img: "comprehensive-overview"
      }, {
        url: "/systemManage",
        name: "系统管理",
        img: "comprehensive-overview"
      }
    ]
  },
  boxiaoAchievementTeach: {
    url: 'edudcs.huixuecloud.cn/fe-pc/b/fe_exam_teacher',
    navs: [
      {
        url: "/",
        name: "成绩",
        img: "comprehensive-overview"
      }
    ]
  },
  boxiaoAchievementStu: {
    url: 'edudcs.huixuecloud.cn/fe-pc/b/fe_exam_student',
    navs: [
      {
        url: "/",
        name: "成绩",
        img: "comprehensive-overview"
      }
    ]
  },

  boxiaoCurriculaStu: {
    url: 'edudcs.huixuecloud.cn/fe-pc/b/fe_courseselect_student',
    navs: [
      {
        url: "/",
        name: "选课",
        img: "comprehensive-overview"
      }
    ]
  },
  boxiaoCurriculaManage: {
    url: 'edudcs.huixuecloud.cn/fe-pc/b/fe_courseselect_manage/',
    navs: [
      {
        url: "/courseboard",
        name: "选课看板",
        img: "comprehensive-overview"
      }, {
        url: "/courselist",
        name: "选课项目",
        img: "comprehensive-overview"
      }, {
        url: "/resultbycourse",
        name: "课程选课结果",
        img: "comprehensive-overview"
      }, {
        url: "/resultbystudent",
        name: "学生选课结果",
        img: "comprehensive-overview"
      }, {
        url: "/systemConfigure",
        name: "系统设置",
        img: "comprehensive-overview"
      }
    ]
  },
  boxiaoArrangingManage: {
    url: 'edudcs.huixuecloud.cn/fe-pc/b/fe_timetable_other',
    navs: [
      {
        url: "/projects",
        tag: "",
        name: "排课",
        img: "comprehensive-overview"
      }, {
        url: "/publish/projectpublish",
        tag: "",
        name: "发布",
        img: "comprehensive-overview"
      }, {
        url: "/adjustcourse",
        tag: "",
        name: "调课",
        img: "comprehensive-overview"
      }, {
        url: "/activity",
        tag: "setting",
        name: "活动管理",
        img: "comprehensive-overview"
      }, {
        url: "/teachingtime",
        tag: "setting",
        name: "教学时间",
        img: "comprehensive-overview"
      }, {
        url: "/temporaryTeacher",
        tag: "setting",
        name: "临时教师",
        img: "comprehensive-overview"
      }, {
        url: "/roomSettings/classroomManagement/classroomType",
        tag: "setting",
        name: "教室管理",
        img: "comprehensive-overview"
      }, {
        url: "/courseSimpleSettings",
        tag: "setting",
        name: "课程简称管理",
        img: "comprehensive-overview"
      }, {
        url: "/teachingClassSimpleSettings",
        tag: "setting",
        name: "教学班简称管理",
        img: "comprehensive-overview"
      }, {
        url: "/integrateClassroomSetting",
        tag: "setting",
        name: "系统设置",
        img: "comprehensive-overview"
      }
    ]
  },
  boxiaoSchoolArranging: {
    url: 'edudcs.huixuecloud.cn/fe-pc/b/fe_timetable_other',
    navs: [
      {
        url: "/zonetable",
        tag: "viewTimetable",
        name: "学部课表",
        img: "comprehensive-overview"
      }, {
        url: "/gradetable",
        tag: "viewTimetable",
        name: "年级课表",
        img: "comprehensive-overview"
      }, {
        url: "/classtable",
        tag: "viewTimetable",
        name: "班级课表",
        img: "comprehensive-overview"
      }, {
        url: "/subjecttable",
        tag: "viewTimetable",
        name: "学科课表",
        img: "comprehensive-overview"
      }, {
        url: "/teachertable",
        tag: "viewTimetable",
        name: "教师课表",
        img: "comprehensive-overview"
      }, {
        url: "/studenttable",
        tag: "viewTimetable",
        name: "学生课表",
        img: "comprehensive-overview"
      }, {
        url: "/roomtable",
        tag: "viewTimetable",
        name: "教室课表",
        img: "comprehensive-overview"
      }
    ]
  },
  boxiaoArrangingSearch: {
    url: 'edudcs.huixuecloud.cn/fe-pc/b/fe_timetable_teacher/teacherTimetable/mineTimeTable',
    navs: [
      {
        url: "/",
        name: "课表",
        img: "comprehensive-overview"
      }
    ]
  },
  boxiaoArrangingStu: {
    url: 'edudcs.huixuecloud.cn/fe-pc/b/fe_timetable_student/studentTimeTable',
    navs: [
      {
        url: "/",
        name: "课表",
        img: "comprehensive-overview"
      }
    ]
  },
  boxiaoQapaperManage: {
    url: 'edudcs.huixuecloud.cn/fe-pc/b/fe_school_questionare_mgr',
    navs: [
      {
        url: "/SingleQuestionnaire",
        tag: "fillform",
        name: "问卷发布",
        permi: "QUESTION_PUBLISH_MANAGER",
        img: "comprehensive-overview"
      },
      {
        url: "/fillInManagement",
        tag: "setting",
        name: "问卷配置",
        permi: "QUESTION_ADMIN_MANAGER",
        img: "comprehensive-overview"
      }
    ]
  },
  boxiaoQapaper: {
    url: 'edudcs.huixuecloud.cn/fe-pc/b/fe_person_questionare',
    navs: [
      {
        url: "/",
        name: "问卷",
        img: "comprehensive-overview"
      }
    ]
  },
  boxiaoElectivesManage: {
    url: 'edudcs.huixuecloud.cn/fe-pc/b/fe_change_section',
    navs: [
      {
        url: "/screenIndex",
        tag: "project",
        name: "选科管理",
        permi: "SCPM100",
        img: "comprehensive-overview"
      },
      {
        url: "/report/sectionAnalysis",
        tag: "",
        name: "年级选科报告",
        permi: "SCPM102",
        img: "comprehensive-overview"
      },
      {
        url: "/report/classReport",
        tag: "",
        name: "班级选科报告",
        permi: "SCPM101",
        img: "comprehensive-overview"
      }
    ]
  },
  boxiaoElectivesStu: {
    url: 'edudcs.huixuecloud.cn/fe-pc/b/fe_section_student/studentIndex',
    navs: [
      {
        url: "/",
        name: "选科",
        img: "comprehensive-overview"
      }
    ]
  },
  boxiaoBuildingRoom: {
    url: 'edudcs.huixuecloud.cn/fe-pc/b/fe_system_schoolbase',
    navs: [
      {
        url: "/buildings",
        name: "楼宇场馆",
        img: "comprehensive-overview"
      },
      {
        url: "/rooms",
        name: "房间管理",
        img: "comprehensive-overview"
      }
    ]
  },
  boxiaoDataDev: {
    url: 'edudcs.huixuecloud.cn/fe-pc/b/fe_data_dev_mgr/',
    navs: [
      {
        url: "/file_report_mgr",
        tag: "hide",
        name: "文件报告管理",
        permi: "PAP701",
        img: "comprehensive-overview"
      },
      {
        url: "/category_mgr",
        tag: "show",
        name: "数据开发",
        permi: "PAP711",
        img: "comprehensive-overview"
      },
      {
        url: "/large_screen_mgr",
        tag: "hide",
        name: "大屏管理",
        permi: "DPM002",
        img: "comprehensive-overview"
      }
    ]
  },
  boxiaoEvaTeachManage: {
    url: 'edudcs.huixuecloud.cn/fe-pc/b/fe_evaluationteacher_manage/',
    navs: [
      {
        url: "/board",
        name: "评教看板",
        permi: "",
        img: "comprehensive-overview"
      },
      {
        url: "/projects",
        name: "评教项目",
        permi: "",
        img: "comprehensive-overview"
      },
      {
        url: "/systemConfigure",
        name: "系统设置",
        permi: "",
        img: "comprehensive-overview"
      },
      {
        url: "/report/reportSchool",
        name: "全校评教报告",
        permi: "EVALUATE_TENANT_MASTER",
        img: "comprehensive-overview"
      }
    ]
  },
  boxiaoEvaTeachReport: {
    url: 'edudcs.huixuecloud.cn/fe-pc/b/fe_evaluationteacher_manage/report/',
    navs: [
      {
        url: "/reportGrade",
        name: "年级评教报告",
        permi: "EVALUATE_GRADE_MASTER",
        img: "comprehensive-overview"
      },
      {
        url: "/reportPersonal",
        name: "个人评教报告",
        permi: "EVALUATE_PERSONAL_REPORT",
        img: "comprehensive-overview"
      }
    ]
  },
  boxiaoEvaTeachStudent: {
    url: 'edudcs.huixuecloud.cn/fe-pc/b/',
    navs: [
      {
        url: "/fe_evaluate_student",
        name: "评教",
        img: "comprehensive-overview"
      }
    ]
  },
  boxiaoSendRecieveFile: {
    url: 'edudcs.huixuecloud.cn/fe-pc/b/fe_file/',
    navs: [
      {
        url: "/filebox",
        name: "文件管理",
        permi: "SFL801",
        img: "comprehensive-overview"
      },
      {
        url: "/fillCollect",
        name: "资料收集",
        permi: "SFL802",
        img: "comprehensive-overview"
      },
      {
        url: "/setting",
        name: "系统设置",
        permi: "SFL803",
        img: "comprehensive-overview"
      }
    ]
  },
  boxiaoSendRecieveFileUser: {
    url: 'edudcs.huixuecloud.cn/fe-pc/b/',
    navs: [
      {
        url: "/fe_file_user",
        name: "收发文件",
        img: "comprehensive-overview"
      }
    ]
  },
  boxiaoListenEvalCourseManage: {
    url: 'edudcs.huixuecloud.cn/fe-pc/b/fe_listenevalcourse_manage/',
    navs: [
      {
        url: "/board",
        name: "教研看板",
        img: "comprehensive-overview"
      },
      {
        url: "/project/list",
        name: "听评课管理",
        img: "comprehensive-overview"
      },
      {
        url: "/evaluationManagement",
        name: "教师评价管理",
        img: "comprehensive-overview"
      },
      {
        url: "/systemconfig",
        name: "系统设置",
        img: "comprehensive-overview"
      }
    ]
  },
  boxiaoListenEvalCourse: {
    url: 'edudcs.huixuecloud.cn/fe-pc/b/',
    navs: [
      {
        url: "/fe_listenevalcourse_teacher",
        name: "听评课",
        img: "comprehensive-overview"
      }
    ]
  },
  boxiaoWorkflowManage: {
    url: 'edudcs.huixuecloud.cn/fe-pc/b/fe_workflow_mgr/',
    navs: [
      {
        url: "/workflowdefine/mgr",
        name: "工作流管理",
        img: "comprehensive-overview"
      },
      {
        url: "/workflowcategorydefine/mgr",
        name: "分类管理",
        img: "comprehensive-overview"
      }
    ]
  },
  boxiaoWorkflowExamine: {
    url: 'edudcs.huixuecloud.cn/fe-pc/b/',
    navs: [
      {
        url: "/fe_workflow_teacher",
        name: "申请审批",
        img: "comprehensive-overview"
      },
    ]
  },
  boxiaoStuAskForLeaveManage: {
    url: 'edudcs.huixuecloud.cn/fe-pc/b/fe_system_school_leave/',
    navs: [
      {
        url: "/leaveFormMgr",
        name: "请假记录管理",
        permi: "",
        img: "comprehensive-overview"
      },
      {
        url: "/democraticApprovalManagement",
        name: "请假审批管理",
        permi: "",
        img: "comprehensive-overview"
      },
      {
        url: "/periodicLeaveManagement",
        name: "周期性请假管理",
        permi: "",
        img: "comprehensive-overview"
      },
      {
        url: "/systemConfiguration",
        name: "系统配置",
        permi: "",
        img: "comprehensive-overview"
      },
      {
        url: "/organizationManagement",
        name: "团队组织管理",
        permi: "",
        img: "comprehensive-overview"
      },
      {
        url: "/leafRecordsForStudentsOfTheWholeSchool",
        name: "全校学生请假记录",
        permi: "LEA013@manage",
        img: "comprehensive-overview"
      },
      {
        url: "/studentLeaveReport",
        name: "全校请假报告",
        permi: "LEA017@manage",
        img: "comprehensive-overview"
      }
    ]
  },
  boxiaoStuAskForLeaveTeacher: {
    url: 'edudcs.huixuecloud.cn/fe-pc/b/fe_system_school_leave/',
    navs: [
      {
        url: "/gradeDirectorIndex",
        name: "年级请假管理",
        permi: "LEA012@manage",
        img: "comprehensive-overview"
      },
      {
        url: "/gradeLeaveReport",
        name: "年级请假报告",
        permi: "LEA012@report",
        img: "comprehensive-overview"
      },
      {
        url: "/headTeacherIndex",
        name: "班级请假管理",
        permi: "LEA011",
        img: "comprehensive-overview"
      },
      {
        url: "/medicalRoom",
        name: "病假记录",
        permi: "LEA015@record",
        img: "comprehensive-overview"
      },
      {
        url: "/medicalApplication",
        name: "病假申请",
        permi: "LEA015@apply",
        img: "comprehensive-overview"
      },
      {
        url: "/ageHandedStudentLeave",
        name: "代学生请假",
        permi: "LEA016@apply",
        img: "comprehensive-overview"
      },
      {
        url: "/leafRecordsForStudentsOfTheWholeSchool",
        name: "全校学生请假记录",
        permi: "LEA013@teacher",
        img: "comprehensive-overview"
      },
      {
        url: "/studentLeaveReport",
        name: "全校请假报告",
        permi: "LEA017@teacher",
        img: "comprehensive-overview"
      },
      {
        url: "/studentStatistics",
        name: "授课班级学生请假统计",
        permi: "LEA016@statistics",
        img: "comprehensive-overview"
      }
    ]
  },
  boxiaoStuAskForLeaveStu: {
    url: 'edudcs.huixuecloud.cn/fe-pc/b/',
    navs: [
      {
        url: "/fe_student_leave",
        name: "请假",
        img: "comprehensive-overview"
      },
    ]
  },
  boxiaoKnowledgeAnalyse: {
    url: 'edudcs.huixuecloud.cn/fe-pc/b/fe_knowledge_analyse/',
    navs: [
      {
        url: "/projectList",
        name: "项目管理",
        permi: "NKNW004",
        img: "comprehensive-overview"
      },
      {
        url: "/gradeReport",
        name: "年级成绩分析",
        permi: "NKNW005",
        img: "comprehensive-overview"
      },
      {
        url: "/subjectReport",
        name: "学科成绩分析",
        permi: "NKNW006",
        img: "comprehensive-overview"
      },
      {
        url: "/classReport",
        name: "班级成绩分析",
        permi: "NKNW007",
        img: "comprehensive-overview"
      },
      {
        url: "/blueprintList",
        name: "命题蓝图",
        permi: "NKNW003",
        img: "comprehensive-overview"
      },
      {
        url: "/scoreList",
        name: "成绩填报",
        permi: "NKNW002",
        img: "comprehensive-overview"
      },
      {
        url: "/analyticalList",
        name: "分析维度管理",
        permi: "NKNW001",
        img: "comprehensive-overview"
      },
      {
        url: "/analyzeDimensions",
        name: "全校维度管理",
        permi: "NKNW000@school",
        img: "comprehensive-overview"
      },
      {
        url: "/systemConfig",
        name: "系统设置",
        permi: "NKNW000@system",
        img: "comprehensive-overview"
      }
    ]
  },
  boxiaoTeacherAttendanceManage: {
    url: 'edudcs.huixuecloud.cn/fe-pc/b/fe_teacher_attendance_mgr/',
    navs: [
      {
        url: "/teacherAttendanceManagement",
        name: "教师考勤管理",
        permi: "",
        img: "comprehensive-overview"
      },
      {
        url: "/attendanceLogManagement",
        name: "考勤管理日志",
        permi: "",
        img: "comprehensive-overview"
      },
      {
        url: "/teacherSCardSupplementRecord",
        name: "考勤管理日志",
        permi: "",
        img: "comprehensive-overview"
      },
      {
        url: "/teacherClockInLog",
        name: "补卡记录管理",
        permi: "",
        img: "comprehensive-overview"
      },
      {
        url: "/batchRefreshResults",
        name: "教师打卡日志",
        permi: "",
        img: "comprehensive-overview"
      },
      {
        url: "/batchRefreshResults",
        name: "批量刷新结果",
        permi: "",
        img: "comprehensive-overview"
      },
      {
        url: "/teacherAttendanceReport",
        name: "考勤报告",
        permi: "",
        img: "comprehensive-overview"
      },
      {
        url: "/teacherLeaveReport",
        name: "请假报告",
        permi: "",
        img: "comprehensive-overview"
      },
      {
        url: "/attendanceGroupSettings",
        name: "考勤组设置",
        permi: "",
        img: "comprehensive-overview"
      },
      {
        url: "/scheduleSetting",
        name: "班次设置",
        permi: "",
        img: "comprehensive-overview"
      },
      {
        url: "/messageReminder",
        name: "消息提醒",
        permi: "",
        img: "comprehensive-overview"
      },
      {
        url: "/leaveManagement",
        name: "请假管理",
        permi: "LET101",
        img: "comprehensive-overview"
      }
    ]
  },
  boxiaoTeacherAttendance: {
    url: 'edudcs.huixuecloud.cn/fe-pc/b/',
    navs: [
      {
        url: "/fe_teacher_attendance_personal",
        name: "教师考勤",
        img: "comprehensive-overview"
      }
    ]
  },
  boxiaoStudentPrizeManage: {
    url: 'edudcs.huixuecloud.cn/fe-pc/b/fe_system_student_prize_mgr/',
    navs: [
      {
        url: "/prizeManage",
        name: "获奖管理",
        permi: "AWA003@manage",
        img: "comprehensive-overview"
      },
      {
        url: "/prizeApprove",
        name: "获奖审批",
        permi: "AWA003@approve",
        img: "comprehensive-overview"
      },
      {
        url: "/prizeGradeManage",
        name: "级别管理",
        permi: "AWA003@syslevrl",
        img: "comprehensive-overview"
      },
      {
        url: "/competitionManage",
        name: "竞赛管理",
        permi: "AWA003@syscomp",
        img: "comprehensive-overview"
      },
      {
        url: "/honourManage",
        name: "荣誉管理",
        permi: "AWA003@honour",
        img: "comprehensive-overview"
      },
      {
        url: "/prizeAnnouncement",
        name: "获奖通报",
        permi: "AWA003@announce",
        img: "comprehensive-overview"
      },
      {
        url: "/classDirectorPrizeManage",
        name: "班级获奖管理",
        permi: "AWA001",
        img: "comprehensive-overview"
      },
      {
        url: "/gradeDirectorPrizeManage",
        name: "年级获奖管理",
        permi: "AWA002",
        img: "comprehensive-overview"
      }
    ]
  },
  boxiaoStudentPrize: {
    url: 'edudcs.huixuecloud.cn/fe-pc/b/',
    navs: [
      {
        url: "/fe_system_student_prize",
        name: "获奖",
        img: "comprehensive-overview"
      }
    ]
  },
  boxiaoFiveEvaluationManage: {
    url: 'edudcs.huixuecloud.cn/fe-pc/b/fe_system_evaluation/',
    navs: [
      {
        url: "/resultIndex/admin",
        name: "结果查看",
        permi: "FIV001@result",
        img: "comprehensive-overview"
      },
      {
        url: "/recordIndex/admin",
        name: "记录管理",
        permi: "FIV001@record",
        img: "comprehensive-overview"
      },
      {
        url: "/personChargeManage",
        name: "负责人管理",
        permi: "FIV001@leader",
        img: "comprehensive-overview"
      },
      {
        url: "/evaluationIndexType",
        name: "指标类型管理",
        permi: "FIV001@type",
        img: "comprehensive-overview"
      },
      {
        url: "/evaluationIndexList",
        name: "评价指标管理",
        permi: "FIV001@index",
        img: "comprehensive-overview"
      },
      {
        url: "/director/grade",
        name: "年级主任五项评比",
        permi: "FIV003",
        img: "comprehensive-overview"
      },
      {
        url: "/director/class",
        name: "班主任五项评比",
        permi: "FIV002",
        img: "comprehensive-overview"
      },
      {
        url: "/recordIndex/school",
        name: "负责人五项评比记录",
        permi: "FIV005",
        img: "comprehensive-overview"
      },
      {
        url: "/resultIndex/school",
        name: "全校五项评比结果",
        permi: "FIV004",
        img: "comprehensive-overview"
      }
    ]
  },
  boxiaoExamMattersManage: {
    url: 'edudcs.huixuecloud.cn/fe-pc/b/fe_exam_mgr/',
    navs: [
      {
        url: "/examManagement",
        name: "考务管理",
        img: "comprehensive-overview"
      },
      {
        url: "/systemSettings",
        name: "系统设置",
        img: "comprehensive-overview"
      }
    ]
  },
  boxiaoMeetApointmentManage: {
    url: 'edudcs.huixuecloud.cn/fe-pc/b/fe_meetting_mgr/',
    navs: [
      {
        url: "/orderMgr",
        name: "预约记录管理",
        img: "comprehensive-overview"
      },
      {
        url: "/otherResourceMgr",
        name: "预约资源管理",
        img: "comprehensive-overview"
      },
      {
        url: "/categoryMgr",
        name: "预约资源类型管理",
        img: "comprehensive-overview"
      },
      {
        url: "/meetCategoryMgr",
        name: "会议室预约配置",
        img: "comprehensive-overview"
      },
      {
        url: "/meetResourceMgr",
        name: "会议室管理",
        img: "comprehensive-overview"
      }
    ]
  },
  boxiaoMeetApointment: {
    url: 'edudcs.huixuecloud.cn/fe-pc/b/',
    navs: [
      {
        url: "/fe_meetting_user",
        name: "预约",
        img: "comprehensive-overview"
      }
    ]
  },
  schoolSourceLib: {
    url: hostMap("//localhost:7351/"),
    navs: [
      {
        url: "/schoolSource",
        name: "校本库",
        img: "school-source"
      }
    ],
  },
  classNotice: {
    url: hostMap('//localhost:7352/'),
    navs: [
      {
        url: '/home',
        name: '班级通知',
        img: 'class-notice',
      }
    ],
  },
  spokenTraining: {
    url: hostMap("//localhost:7353/"),
    navs: [
      {
        url: "/practiceList",
        name: "英语口语练习",
        img: "spoken-training",
      },
    ],
  },
  dataView: {
    url: hostMap("//localhost:7354/"),
    navs: [
      {
        url: "/interactiveData",
        name: "互动数据",
        img: "spoken-training",
      },
      {
        url: "/teacherContribute",
        name: "教师贡献",
        img: "teacher-contribute",
      },
      {
        url: "/sectionedClass",
        name: "班级分组",
        img: "sectioned-class",
      },
    ],
  },
  typicalExercises: {
    url: hostMap('//localhost:7355/'),
    navs: [
      {
        url: '/home',
        name: '典题本',
        img: 'typical-exercises',
      }
    ],
  },
  chatAi: {
    url: hostMap('//localhost:7356/'),
    navs: [
      {
        url: "/assistant",
        name: "AI助手",
        img: "chat-ai-assistant",
      },
       {
        url: "/toolbox",
        name: "AI工具箱",
        img: "chat-ai-toolbox",
      },
      {
        url: "/libai",
        name: "AI李白",
        img: "chat-ai-libai",
      },
      {
        url: "/explain",
        name: "AI秘籍",
        img: "chat-ai-explain",
      },
    ],
  },
  cloudNotes: {
    url: hostMap('//localhost:7357/'),
    navs: [
      {
        url: '/notes',
        name: '我的笔记',
        img: 'note',
      },
      {
        url: '/recycle',
        name: '回收站',
        img: 'recycle',
      }
    ],
  },
  clientDownload: {
    url: hostMap('//localhost:7358/'),
    navs: [
      {
        url: '/home',
        name: '客户端下载',
        img: 'client-download',
      }
    ],
  },
  classPortal: {
    url: hostMap('//localhost:7359/'),
    navs: [
      {
        url: '/home',
        name: '首页',
        img: 'class-portal-home',
      },
      {
        url: '/manage',
        name: '自主管理',
        img: 'class-portal-manage',
      },
      {
        url: '/share',
        name: '学习共享',
        img: 'class-portal-share',
      }
    ],
  },
  applyApp: {
    url: hostMap('//localhost:7360/'),
    navs: [
      {
        url: '/apps',
        name: '应用',
        img: 'apps',
      },
      {
        url: '/applyRecords',
        name: '申请记录',
        img: 'applyRecords',
      },
      {
        url: '/permissionApproval',
        name: '权限审批',
        img: 'permissionAppr',
      }
    ],
  },
  manageSchoolGroup: {
    url: hostMap('//localhost:7361/'),
    navs: [
      {
        url: '/groupManager',
        name: '管理',
        img: 'school-group',
      }
    ],
  },
  manageParentsLessons: {
    url: hostMap('//localhost:7362/'),
    navs: [
      {
        url: '/lessonManage',
        name: '课程管理',
        img: 'lesson-manage',
      },
      {
        url: '/operateRecord',
        name: '操作记录',
        img: 'record',
      }
    ],
  },
  limitUsageApp: {
    url: hostMap('//localhost:7364/'),
    navs: [
      {
        url: '/timeLimitManage',
        name: '限时管理',
        img: 'limitManage',
      },
      {
        url: '/operateRecords',
        name: '操作记录',
        img: 'record',
      }
    ],
  },
  parentsLessons: {
    url: hostMap('//localhost:7363/'),
    navs: [
      {
        url: '/lessonList',
        name: '课程列表',
        img: 'lesson-list',
      }
    ],
  }
};
